<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="온에어쇼핑" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container on-air">
				<div class="onair-swiper-wrap">
					<div class="page-sub-header hidden-mobile">
						<h3 class="page-sub-title">제품은 리얼하게, 혜택은 풍성하게 <span class="prima">라이브로 쇼핑을 즐기세요.</span></h3>
					</div>
					<div class="onair-swiper-container">
						<div v-show="hasActiveBrc">
							<swiper ref="swiper" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
								<swiper-slide v-for="(item, idx) in selectedDateMeta.onairBrcList" :key="idx">
									<a class="onair-item" @click="onClickToRefUrl($event, item)">
										<div class="onair-item-header">
											<!-- closed, ready 클래스로 구분 -->
											<span class="content-status" v-bind:class="{ closed: item.closed, ready: item.ready }">{{ item.status }}</span>
											<div class="content-period">
												<span class="content-date">{{ bannerDate }}</span>
												<span class="content-time">{{ item.brcBgngHhmm }} ~ {{ item.brcEndHhmm }}</span>
											</div>
										</div>
										<div class="onair-item-info">
											<ul class="content-list">
												<li class="content-list-desc">
													<span class="content-list-title">{{ item.onairBrcNm }}</span>
												</li>
												<!-- <li class="content-list-desc">
													<span v-if="parseInt(item.dscntRt) > 0" class="content-list-disc">{{ item.dscntRt }}</span>
													<span class="content-list-price">
														<span class="price-num">{{ Number(item.manprdPrc).toLocaleString() }}</span>
														원
													</span>
												</li> -->
												<li class="content-list-desc">
													<span class="content-list-seller">{{ item.chnlNm }}</span>
												</li>
											</ul>
										</div>
										<div class="onair-item-image">
											<img :src="item.imgFileUrl" :alt="item.onairBrcNm" />
										</div>
									</a>
								</swiper-slide>
							</swiper>
							<div class="onair-swiper-controls">
								<swiper-controls :swiper="thisSwiper" :auto="true"></swiper-controls>
							</div>
						</div>
						<div v-if="!hasActiveBrc" class="onair-ready-scrn">
							<div class="onair-ready-text-wrap">
								<span class="onair-ready-text">라이브 방송 준비 중입니다.</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="onair-schedule">
				<h3 class="onair-schedule-title">편성표</h3>
				<div class="onair-schedule-area">
					<div class="container">
						<div class="onair-schedule-area-header">
							<span class="sch-month">{{ calendarDate }}</span>
							<div class="sch-button-wrap visible-mobile">
								<button type="button" class="sch-button prev" @click="onClickToChangeWeek($event, NUMBER_OF_DAYS_IN_A_WEEK * -1)">
									<i class="icon">
										<icon name="icon-arrow-r" />
									</i>
								</button>
								<button type="button" class="sch-button next" @click="onClickToChangeWeek($event, NUMBER_OF_DAYS_IN_A_WEEK * 1)">
									<i class="icon">
										<icon name="icon-arrow-r" />
									</i>
								</button>
							</div>
						</div>
						<div class="flex-1">
							<button type="button" class="sch-day-btn prev hidden-mobile" @click="onClickToChangeWeek($event, NUMBER_OF_DAYS_IN_A_WEEK * -1)">
								<i class="icon">
									<icon name="icon-arrow-r" />
								</i>
							</button>
							<ul class="onair-schedule-list">
								<li class="onair-sch-item" v-for="(item, idx) in calendar" :value="item.date" :key="idx">
									<!-- 편성표 데이터가 없는 경우 disabled 클래스 추가 -->
									<button type="button" class="onair-sch-button" :class="{ 'is-active': idx == selectedDateMeta.day, disabled: item.onairBrcList.length < 1 }" @click="onClickToChangeDay($event, item)">
										<span class="btn-date">{{ item.dd }}</span>
										<span class="btn-dayname">{{ item.dayNm }}</span>
									</button>
								</li>
							</ul>
							<button type="button" class="sch-day-btn next hidden-mobile" @click="onClickToChangeWeek($event, NUMBER_OF_DAYS_IN_A_WEEK * 1)">
								<i class="icon">
									<icon name="icon-arrow-r" />
								</i>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="container on-air">
				<div v-if="hasActiveBrc" class="onair-product-list">
					<div class="onair-product-wrap" v-for="(item, idx) in selectedDateMeta.onairBrcList" :key="idx" v-bind:class="{ closed: item.closed, ready: item.ready }">
						<div class="onair-product-schedule">
							<span class="sch-status">{{ item.status }}</span>
							<div class="sch-time-wrap">
								<span class="sch-start">{{ item.brcBgngHhmm }}</span>
								<span class="sch-end">~ {{ item.brcEndHhmm }}</span>
							</div>
						</div>
						<a class="onair-product-item" @click="onClickToRefUrl($event, item)">
							<div class="onair-product-info">
								<div class="info-left">
									<span class="product-info-title">{{ item.onairBrcNm }}</span>
									<span class="product-info-seller">{{ item.chnlNm }}</span>
								</div>
								<div class="info-right">
									<span v-if="parseInt(item.dscntRt) > 0" class="product-info-disc">{{ item.dscntRt }}</span>
									<span class="product-info-price">
										<span class="price-number">{{ Number(item.manprdPrc).toLocaleString() }}</span>
										원
									</span>
								</div>
							</div>
							<img :src="item.imgFileUrl" :alt="item.onairBrcNm" />
							<div class="close-products">
								<span class="close-text">라이브가 종료되었습니다.</span>
							</div>
						</a>
					</div>
				</div>
				<div v-else class="onair-ready-scrn">
					<div class="onair-ready-text-wrap">
						<span class="onair-ready-text">라이브 방송 준비 중입니다.</span>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
import Icon from '@/components/common/Icon';
import SwiperControls from '@/components/common/SwiperControls';

import { mapGetters } from 'vuex';
import { MUT_SHOW_ALERT } from '@/store/_mut_consts';
import { ACT_GET_ONAIR_BRC_LIST } from '@/store/_act_consts';

import { lengthCheck, getItems, queryToValue, setPaging, hhmmToDateFormat, timestampToDateFormat, dateToDateFormat } from '@/assets/js/utils';
import { DateFormat } from '@/assets/js/DateUtils';

export default {
	name: 'Onair',
	components: { PageHeader, Icon, SwiperControls },
	data: () => ({
		thisSwiper: null,
		activeIndex: 0,
		swiperOption: {
			loop: false,
			activeIndex: 0,
			slidesPerView: 1,
			spaceBetween: 0,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			// on: {
			// 	init: function() {
			// 		this.autoplay.start();
			// 	},
			// 	touchEnd: function() {
			// 		this.autoplay.start();
			// 	},
			// 	observerUpdate: function() {
			// 		this.autoplay.start();
			// 	},
			// },
			// watchOverflow: false,
			navigation: {
				nextEl: '.on-air .swiper-button-next',
				prevEl: '.on-air .swiper-button-prev',
			},
			pagination: {
				el: '.on-air .swiper-pagination',
				type: 'fraction',
			},
			// breakpoints: {},
		},

		pagingState: {
			hasMore: false,
			totalCount: 0,
			pageSize: 2 ** (31 - 1),
			pageNo: 1,
		},

		calendar: [],
		selectedDateMeta: {
			// ymd: dateToDateFormat(now, DateFormat),
			// day: now.getDay(),
			// dayNm: now.toLocaleDateString('ko-KR', { weekday: 'long' }),
			// date: now,
			// onairBrcList: [],
		},
		baseDayCnt: 0,
		HHMM_DELIMITER: ':',
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('onair', ['STATUS', 'CENTER_OF_THE_WEEK', 'NUMBER_OF_DAYS_IN_A_WEEK']),

		campnId() {
			return this.campn.campnId;
		},
		bannerDate() {
			return `${this.selectedDateMeta.ymd} (${this.selectedDateMeta.dayNm.substring(0, 1)})`;
		},
		calendarDate() {
			return this.selectedDateMeta.ymd.substring(0, 7);
		},
		hasActiveBrc() {
			return this.selectedDateMeta.onairBrcList.length > 0;
		},
	},
	watch: {
		calendar() {
			this.getOnairBrcList();
		},
		selectedDateMeta() {
			const swiper = this.$refs.swiper.$swiper;
			this.$nextTick(() => {
				swiper.slideTo(0);
				setTimeout(() => {
					swiper.update();
					swiper.autoplay.stop();
				}, 100);
			});

			this.$nextTick(() => {
				setTimeout(() => {
					swiper.autoplay.start();
				}, 200);
			});
		},
	},
	async created() {
		this.generateWeekDays(0);
		this.setSelectedDateMeta();
	},
	mounted() {
		this.$nextTick(() => {
			this.thisSwiper = this.$refs.swiper.$swiper;
		});

		const now = new Date();
		this.setSelectedDateMeta(now.getDay(), now, this.calendar.find((cal) => cal.ymd === dateToDateFormat(now, DateFormat)).onairBrcList);

		// console.log(
		// 	"campnId]", this.campn.campnId,
		// 	"\nselectedDateMeta]", this.selectedDateMeta,
		// 	"\n[calendar]", this.calendar
		// )
	},
	methods: {
		// event ======================================================================
		onSwiperChange() {
			this.activeIndex = this.thisSwiper.realIndex;
		},
		// gotoSwiper(idx) {
		// 	this.thisSwiper.slideTo(idx)
		// },
		onClickToRefUrl(e, brc) {
			const [bgngDate, endDate] = this.getBgngAndEndDateStampFromStr(brc.brcBgngYmd, brc.brcBgngHhmmOrg, brc.brcEndYmd, brc.brcEndHhmmOrg);
			const rslCargo = this.decideBrcStatus(bgngDate, endDate);

			if (this.STATUS.SOON === rslCargo.scd) {
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '방송 준비중 입니다.' });
			}

			let url = brc.brcUrl;
			const prefix = 'https://';

			if (!url.startsWith(prefix)) {
				url = prefix + url;
			}
			window.open(url);
		},
		onClickToChangeWeek(e, dayFromToday) {
			this.baseDayCnt += dayFromToday;
			this.generateWeekDays(this.baseDayCnt);

			this.setSelectedDateMeta(this.CENTER_OF_THE_WEEK, this.calendar[this.CENTER_OF_THE_WEEK].date, this.calendar[this.CENTER_OF_THE_WEEK].onairBrcList);
		},
		onClickToChangeDay(e, calObj) {
			this.setSelectedDateMeta(calObj.date.getDay(), calObj.date, calObj.onairBrcList);
		},

		// custom fn ======================================================================
		generateWeekDays(dayFromToday) {
			const today = new Date();
			const dayOfWeek = today.getDay();
			const startOfWeek = new Date(today);
			startOfWeek.setDate(today.getDate() - dayOfWeek + dayFromToday);

			this.calendar = Array(7)
				.fill()
				.map((_, i) => {
					const date = new Date(startOfWeek);
					date.setDate(startOfWeek.getDate() + i);

					return {
						dayNm: date.toLocaleDateString('ko-KR', { weekday: 'long' }).substring(0, 1),
						dd: date.toLocaleDateString('ko-KR').split('.')[2],
						date: date,
						ymd: dateToDateFormat(date, DateFormat),
						onairBrcList: [],
					};
				});
		},
		setSelectedDateMeta(selectedDay = new Date().getDay(), date = new Date(), brcList = []) {
			this.selectedDateMeta = {
				ymd: dateToDateFormat(date, DateFormat),
				day: selectedDay,
				dayNm: date.toLocaleDateString('ko-KR', { weekday: 'long' }),
				date: date,
				onairBrcList: brcList,
			};
		},

		createDateTimeStampFromStr(yyMMdd, hhmm) {
			// if (!/^\d{4}-\d{2}-\d{2}$/.test(yyMMdd) || !/^\d{2}:\d{2}$/.test(hhmm)) {
			// 	throw new Error("Invalid input format. Expected 'YYYY-MM-DD' and 'HH:MM'");
			// }
			const [year, month, day] = yyMMdd.substring(0, 10).split('-').map(Number);
			const hours = parseInt(hhmm.slice(0, 2), 10);
			const minutes = parseInt(hhmm.slice(2), 10);

			return new Date(year, month - 1, day, hours, minutes);
		},
		getBgngAndEndDateStampFromStr(brcBgngYmd, brcBgngHhmm, brcEndYmd, brcEndHhmm) {
			const bgngDate = this.createDateTimeStampFromStr(brcBgngYmd, brcBgngHhmm);
			const endDate = this.createDateTimeStampFromStr(brcEndYmd, brcEndHhmm);
			return [bgngDate, endDate];
		},

		decideBrcStatus(bgngDate, endDate, currDate = new Date()) {
			const bgngTime = bgngDate.getTime();
			const endTime = endDate.getTime();
			const currTime = currDate.getTime();

			let status = this.STATUS.CLOSED;
			if (currTime < bgngTime) {
				status = this.STATUS.SOON;
			} else if (bgngTime <= currTime && currTime <= endTime) {
				status = this.STATUS.ON_AIR;
			} else if (currTime < endTime) {
				status = this.STATUS.CLOSED;
			}

			let rslCargo = {
				status: '준비중',
				closed: false,
				ready: false,
			};
			switch (status) {
				case this.STATUS.SOON:
					rslCargo = {
						scd: this.STATUS.SOON,
						status: '준비중',
						closed: false,
						ready: false,
					};
					break;
				case this.STATUS.ON_AIR:
					rslCargo = {
						scd: this.STATUS.ON_AIR,
						status: '방송중',
						closed: false,
						ready: true,
					};
					break;
				case this.STATUS.CLOSED:
					rslCargo = {
						scd: this.STATUS.CLOSED,
						status: '종료',
						closed: true,
						ready: false,
					};
					break;
				default:
					break;
			}

			return rslCargo;
		},
		sortQuickly(brcList) {
			if (brcList.length < 1) return [];

			let left = [],
				equal = [],
				right = [];

			for (let brc of brcList) {
				const [bgngDate, endDate] = this.getBgngAndEndDateStampFromStr(brc.brcBgngYmd, brc.brcBgngHhmm, brc.brcEndYmd, brc.brcEndHhmm);
				const rslCargo = this.decideBrcStatus(bgngDate, endDate);

				if (this.STATUS.SOON === rslCargo.scd) {
					left.push(brc);
				} else if (this.STATUS.CLOSED === rslCargo.scd) {
					right.push(brc);
				} else {
					equal.push(brc);
				}
			}

			left.sort((a, b) => a.brcBgngHhmm.replace(this.HHMM_DELIMITER, '') - b.brcBgngHhmm.replace(this.HHMM_DELIMITER, ''));
			right.sort((a, b) => b.brcBgngHhmm.replace(this.HHMM_DELIMITER, '') - a.brcBgngHhmm.replace(this.HHMM_DELIMITER, ''));

			return [...equal, ...left, ...right];
		},

		// promis ======================================================================
		async getOnairBrcList() {
			const modelAttr = {
				campnId: this.campnId,
				// brcBgngYmd: this.selectedDateMeta.ymd,
				// brcEndYmd: this.selectedDateMeta.ymd,
				brcBgngYmd: this.calendar[0].ymd,
				brcEndYmd: this.calendar[this.calendar.length - 1].ymd,
				...this.pagingState,
			};

			this.$store
				.dispatch(`onair/${ACT_GET_ONAIR_BRC_LIST}`, modelAttr)
				.then((res) => {
					// console.log(res);
					let onairBrcList = [];

					if (lengthCheck(res)) {
						onairBrcList = this.sortQuickly(getItems(res)).map((brc) => {
							const [bgngDate, endDate] = this.getBgngAndEndDateStampFromStr(brc.brcBgngYmd, brc.brcBgngHhmm, brc.brcEndYmd, brc.brcEndHhmm);
							const rslCargo = this.decideBrcStatus(bgngDate, endDate);

							return {
								onairBrcNm: brc.onairBrcNm,
								dscntRt: brc.dscntRt + '%',
								manprdPrc: brc.manprdPrc,
								brcBgngYmd: brc.brcBgngYmd.substring(0, 10),
								brcBgngHhmm: hhmmToDateFormat(brc.brcBgngHhmm, this.HHMM_DELIMITER),
								brcBgngHhmmOrg: brc.brcBgngHhmm,
								brcEndYmd: brc.brcEndYmd.substring(0, 10),
								brcEndHhmm: hhmmToDateFormat(brc.brcEndHhmm, this.HHMM_DELIMITER),
								brcEndHhmmOrg: brc.brcEndHhmm,
								chnlNm: brc.chnlNm,
								imgFileUrl: brc.imgFileUrl,
								brcUrl: brc.brcUrl,
								status: rslCargo.status,
								closed: rslCargo.closed,
								ready: rslCargo.ready,
							};
						});
					} else {
						this.selectedDateMeta.onairBrcList = [];
					}

					return onairBrcList;
				})
				.then((a) => {
					if (a.length > 0) {
						this.calendar.forEach((cal, _) => {
							const ymdBrcList = a.filter((brc) => cal.ymd === brc.brcBgngYmd);
							cal.onairBrcList.push(...ymdBrcList);
						});
					}
				})
				.catch(console.error);
		},
	},
};
</script>
